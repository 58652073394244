/**
 * Site header
 */
.site-header {
    // border-top: 5px solid $grey-color-dark; why top? comment it
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
    // add instagram style
    background-color: #517fa4;
    background-image: url("/img/noise.png"),-webkit-gradient(linear,left top,left bottom,from(#517fa4),to(#306088));
    background-image: url("/img/noise.png"),-webkit-linear-gradient(top,#517fa4,#306088);
    background-image: url("/img/noise.png"),-moz-linear-gradient(top,#517fa4,#306088);
    background-image: url("/img/noise.png"),-o-linear-gradient(top,#517fa4,#306088);
    background-image: url("/img/noise.png"),-ms-linear-gradient(top,#517fa4,#306088);
    background-image: url("/img/noise.png"),linear-gradient(top,#517fa4,#306088);
    //background-position: 50% 50%;
    //-webkit-background-size: 30px 20px,auto;
    //background-size: 30px 20px,auto;
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: #fff; //$grey-color-dark;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: #fff; //$text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    .page-link i{
        display:inline;
    }

    .page-link span{
        display:none;
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .page-link span{
            display:inline;
        }

        .page-link i{
            display:none;
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    //padding: $spacing-unit 0;
    padding: 10px 0;
    font-weight:bold;
    font-size: 12px;
    color: #1c5380;
    text-transform: uppercase;
    text-align: center;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: calc($spacing-unit / 2);
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: calc(-1 * $spacing-unit / 2);
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: calc($spacing-unit / 2);
    padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    text-align: justify;
    padding: $spacing-unit 0;
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        //margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    font-family: monospace;
    color: $grey-color;
    margin-right: 20px; // add
}

.post-link {
    display: inline!important; //block;
    font-size: $small-font-size; //24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 36px;  //42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 30px;
    }
}

.post-content {
    text-align: justify;
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

// add for comment
.divider {
    position: relative;
    font-size: 1em;
    z-index: 1;
    overflow: hidden;
    text-align: center; }
   .divider:before, article .divider:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 49%;
    height: 2px;
    content: '\a0';
    background-color: #f0f0f0;
}
.divider:before {
    margin-left: -50%;
    text-align: right;
}
.divider:after {
    margin-left: 1%;
}
.divider a {
    margin-left:1em;
    margin-right:1em;
}

/**
 * dark mode
 */
@media (prefers-color-scheme: dark) {
    .site-header {
        background-image: None;
        background-color: #131862; //#2f2f2f;
        border-bottom: 1px solid #000;
    }
    .site-footer {
        color: #c7c7c7;
        border-top: 1px solid #000;
        background-color: #131862; // new Jun 2024
    }
    .site-nav {
        @include media-query($on-palm) {
            background-color: #2f2f2f;
        }
    }
}
