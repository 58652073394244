@charset "utf-8";

@use "sass:color";

// Our variables
$base-font-family: system-ui, -apple-system, BlinkMacSystemFont, "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, "PingFang SC", "Noto Sans", "Noto Sans CJK", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #222;  //#111;
$background-color: #edeeef;  //#fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: color.adjust($grey-color, $lightness: 40%, $space: hsl);
$grey-color-dark:  color.adjust($grey-color, $lightness: -25%, $space: hsl);

$on-palm:          600px;
$on-laptop:        800px;



// Using media queries with like this:
// @include media-query($palm) {
//     .wrapper {
//         padding-right: calc($spacing-unit / 2);
//         padding-left: calc($spacing-unit / 2);
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
